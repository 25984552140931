import {React,
} from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
// import Dreamgrid from './components/Dreamgrid';
import ImageGen from './components/ImageGen';
// import Chatdots from "./components/Chatdots";




function App() {


  return (
    <ChakraProvider theme={theme}>
    <ImageGen></ImageGen>
      
    </ChakraProvider>
  );
}

export default App;

