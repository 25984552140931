import { Flex, Text, Input, Button, Box, FormControl, Center, Image, Grid, GridItem,Spinner} from "@chakra-ui/react";
// import React, { useState } from "react";
import { useEffect, useState } from "react";


// const backend_url = "http://localhost:5050"
const backend_url = "https://realtor-basic-backend-kokwttjzkq-uc.a.run.app"

function ImageGrid({ imageUrls }) {
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {imageUrls.map((url, index) => (
          <GridItem key={index}>
            <Image src={url} alt={`Image ${index}`} />
          </GridItem>
        ))}
      </Grid>
    );
  }
  


const ImageGen = ({currentUser=null, entityName = "Company", recipeType = null }) => {
    const [testimage, setTestImage] = useState(null)
    const [imagePrompt, setImagePrompt] = useState("")
    const [generating, setGenerating] = useState(false)
    const [generatingDream, setGeneratingDream] = useState(false)
    const [generatingLikeImages, setGeneratingLikeImages] = useState(false)
    const [likeImages, setLikeImages] = useState(null)
    useEffect(() => {

        console.log(imagePrompt)
        
    }, [imagePrompt])   

    const fetchGenerateImage = async (prompt) => {
        setGenerating(true)
        try{
        console.log("imagePrompt",imagePrompt)
        let imagePromptToSend
        if( imagePrompt !== ""){
            imagePromptToSend = imagePrompt
        }
        else{
            imagePromptToSend = "Cool House"
        }


        let result = await fetch(backend_url + "/generate/image",
          {method: 'POST', 
          headers: {
              'Content-Type': 'application/json'
            },
          body: JSON.stringify({"message": imagePromptToSend})})
          let result_to_json = await result.json()
          console.log("here1")
          console.log("here2" ,result_to_json)
          setTestImage(result_to_json.image_url)
          return result_to_json.image_url
        }
        catch(e){
            return false
        }
      }

    const fetchLikeImages = async (inputUrl) => {
    // setGenerating(true)
    console.log("fetchLikeImages",inputUrl)
    let result = await fetch(backend_url + "/search/images",
        {method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"message": inputUrl})})
        let result_to_json = await result.json()
        console.log("here1")
        console.log("here2" ,result_to_json)
        setLikeImages(result_to_json.images)
        // setGenerating(false)
        // return result_to_json.image_url
    }


    const handleImageGenClick = async () =>{
        setGenerating(true)
        setGeneratingDream(true)
        setGeneratingLikeImages(true)
        let inputUrl = await fetchGenerateImage(imagePrompt)
        setGeneratingDream(false)
        if (inputUrl !== false){
            try{
                await fetchLikeImages(inputUrl)
            }
            catch(e){
                window.alert("backend AI error occured")
            }
        }
        else{
            window.alert("backend AI error occured")
        }
        setGeneratingLikeImages(false)
        setGenerating(false)
    }



    return (

          <Flex position={"relative"} marginBottom={"8vh"} marginTop={"15vh"} direction={["column","column","column"]} justifyContent="center"  width="100%">

            <Center>
              <Flex direction={"column"}>
                <Text fontSize={"40px"} alignSelf={"center"} marginInline={"20px"}>Dream Home Generator</Text>
                <Text alignSelf={"center"} marginInline={"20px"}>My dream home is a ______________</Text>
              </Flex>
            </Center>
            <Center width={"100%"}>
            <FormControl onSubmit={handleImageGenClick}>
            <Center>
              <Input width={["100%","75%","50%"]} marginTop={"20px"} marginBottom={"20px"} type="text" placeholder='e.g. "Cool House"'
              value={imagePrompt} 
              onChange={event => setImagePrompt(event.target.value)}
              onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                      if (!generating){
                          handleImageGenClick()
                      }
                  }
              }} />
              </Center>
            </FormControl>
            </Center>
            <Center>
            <Button onClick={handleImageGenClick} minW="80px" width={"30%"}>{!generating? ("Generate Dream Home"):(
                <>
                <Spinner
                    thickness='4px'
                    speed='0.85s'
                    size='md'
                    />
                </>
            )}</Button> 
            </Center>
            <Center>
            <Flex flexDir={"column"}>
            <Center>
                <Flex marginTop={"50px"}>
                {testimage ? <Image width={"400px"} height={"400px"} alt="test" src={testimage}/> : null}
                </Flex>
                </Center>
                <Center>
                <Flex flexDir={"column"} marginTop={"50px"}>
                            {likeImages ?<> <Text textAlign={"center"}>Explore Real Homes</Text><ImageGrid imageUrls={likeImages}/></> : null}
                </Flex>
                </Center>
            </Flex>
            </Center>
          </Flex>


      );
};

export default ImageGen;